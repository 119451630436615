import React from "react";
import _ from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from "gatsby"
import ContentCard from '../components/ContentCardTest/ContentCard';
import ContactModal from '../components/generic/ContactModal/ContactModal';
import Layout from "../containers/layout";
import SEO from '../components/seo';

const ZwangerConnect = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      page: sanityZwangerConnectPage {
        title,
        description
      },
      categories: allSanityLessonCategory {
        edges {
          node {
            name
          }
        }
      }
      courses: allSanityZwangerConnect {
        edges {
          node {
            title
            subtitle
            facts
            slug {
              current
            }
            description
            buttonText
            thumbnail {
              asset {
                url
              }
            }
            lessonCategories {
              lessonCategory {
                name
              }
            }
          }
        }
      }
    }
  `);
  const [showModal, setShowModal] = React.useState(false);
  const [courses, setCourses] = React.useState(data.courses.edges);
  const [filter, setFilter] = React.useState('All');
  const categories = data.categories.edges;
  const { title, description } = data.page;

  const handleModalClose = React.useCallback(() => {
    setShowModal(false);
  }, []);

  const getCoursesData = (filter) => {
    if (filter) {
      setFilter(filter);
      const filteredCourses =
        data.courses.edges.filter((c) => c.node.lessonCategories.find((l) => l.lessonCategory.name === filter));
      setCourses(filteredCourses);
    } else {
      setFilter("All");
      setCourses(data.courses.edges);
    }
  }

  return (
    <Layout>
      <SEO title="ZwangerConnect" />
      <div className="wrap">
        <div className="row center-lg">
          <h1 className="filter__title">{title}</h1>
        </div>
        {description && (
          <div className="row center-lg">
            <p className="filter__desc">{description}</p>
          </div>
        )}
        <div className="row center-lg">
          <div>
            {categories.length > 0 && (
              <section className="filter">
                <div className={`filter__item ${filter === 'All' && 'active'}`} onClick={() => getCoursesData()}>Alles</div>
                {categories.map((c) => (
                  <div className={`filter__item ${filter === c.node.name && 'active'}`} onClick={() => getCoursesData(c.node.name)}>{c.node.name}</div>
                ))}
              </section>
            )}
            <div className="courses__overview">
              {courses.length > 0 ? (
                <>
                  {courses.map((c, index) => (
                    <ContentCard classname='courses__item courses__item--big' size="small" image={c.node.thumbnail.asset.url} >
                      <div className="courses__inner">
                        <div>
                          <h3>{c.node.title}</h3>
                          <span className="courses__date">{c.node.subtitle}</span>
                          {c.node.facts.length && (
                            <ul>
                              {c.node.facts.map((f) => (
                                <>
                                  <li>{f}</li>
                                </>
                              ))}
                            </ul>
                          )}
                          <p>{c.node.description}</p>
                        </div>
                        <Link className="courses__link" to={`/zwangerconnect/${c.node.slug.current}`}>
                          <button className='button courses__button'>{c.node.buttonText || 'Meer informatie'}</button>
                        </Link>
                      </div>
                    </ContentCard>
                  ))}
                </>
              ) : (
                  <div className="filter__no-result">
                    <h3>Op dit moment zijn er geen resultaten voor de categorie "{filter.toLowerCase()}"</h3>
                    <p>Heeft u interesse? Laat het ons weten door contact op te nemen.</p>
                    <button onClick={() => setShowModal(true)} className='button button--secondary'>Neem contact op!</button>
                  </div>
                )}
            </div>
          </div>
        </div>
        <ContactModal
          open={showModal}
          onClose={handleModalClose}
        />
      </div>
    </Layout>
  )
}

export default ZwangerConnect
